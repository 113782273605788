/*=================== title & header section =========================*/
    /* This is the question text, [h2] */
    .debate-question { 
        font-size: 2.5rem;
        margin: 0.75rem 0;
        color: var(--dark-blue);
        hyphens: auto;
        cursor: pointer;
    }

    /* this is the header, which contains all the elements below [header] */
    .debate-header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

        .owner-chip /* [section] the top left section that includes the avatar, the username and the date*/{
            margin-right: auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: baseline;
            flex-wrap: wrap;
            width: 15%;
            max-height: 3rem;
        }

        /* The avatar appearing in the top left corner of the header, [img] */
        #debate-avatar {
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            margin-right: 0.5rem;
            cursor: pointer;
        }

        /* the little tag next to the avatar, [span] */
        #debate-creator { 
            background-color: #aed4ff;
            color: var(--dark-blue);
            padding: 0.25rem 0.5rem;
            font-size: 0.75rem;
            font-weight: 600;
            border-radius: 10px;
            cursor: pointer;
            flex: 1 1 auto;
            white-space: nowrap;
        }

        /* the date next to the user, [span] */
        #debate-creation-date {
            margin: 0.25rem;
            font-size: 0.75rem;
            flex: 1 1 auto;
            white-space: nowrap;
        }

        /* the viewership next to the creation date, [span] */
        #viewership {
            color: var(--red);
            margin-left: auto;
            font-size: 0.875rem;
        }

        .options-panel {
            position: relative;
        }
            
            /* The option items (li) in the threedotted pannel at the top rght corner of a debate  */
            .options-panel-item { 
                padding: 0.5rem 1.5rem 0.5rem 0.5rem;
                margin: 0.5rem 1rem 0.5rem 0.25rem;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                cursor: pointer;
                border-radius: 10px;
            }

                /* The svg inside the option item (li) */
                .options-panel-item svg {
                    margin-right: 0.5rem;
                    font-size: 1.25rem;
                }

            .options-panel-item:hover {
                background-color: var(--white-blue);
                font-weight: 600;
            }

/*=================== title & header section [END] =========================*/

/*=================== Changed your mind =========================*/

#changed-your-mind-button { /* button that appears under the charts*/
    margin: 0.25rem;
    color: var(--dark-blue);
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0.25rem;
    display: flex;
    align-items: center;
    font-weight: 600;
}

    #changed-your-mind-button svg {
        margin: 0 0.25rem;
        font-size: 1rem;
    }

    #changed-your-mind-button:hover {
        color: var(--strong-blue);
    }

    @media (max-width: 30rem) {
        #changed-your-mind-button {
            font-size: 0.5rem;
        }

        #changed-your-mind-button svg {
            font-size: 0.75rem;
        }
    }
/*=================== Changed your mind [END] =========================*/