@media (max-width: 30rem) {
    .argo {
        margin: 1.25rem 0.25rem;
    }
    
    .insight-section svg { /* this insight lightbulb icon*/
        font-size: 0.75rem;
    }

    .insight-section p {
        font-size: 0.5rem;
        padding: 0;
    }
    
    .insight-section .insight-number {
        font-size: 0.5rem;
        margin: 0 0.25rem 0 0;
        padding: 0;
    }

    .inside-argo-button {
        margin: 0;
        font-size: 0.75rem;
    }

    .inside-argo-button svg {
        font-size: 0.75rem;
    }

    #argo-text-body {
        font-size: 0.75rem;
        margin-block-start: 0;
        margin-block-end: 0;
        margin: 0.25rem;
    }

    .owner-options-display-button { /* the three-dotted button used to display the owner options */
        margin: 0;
        padding: 0;
        border-radius: 50%;
        cursor: pointer;
        font-size: 0.75rem;
    }
    
    .owner-button {
        margin: 0rem;
        padding: 0 0.25rem;
        border-radius: 50%;
        cursor: pointer;
        font-size: 0.75rem;
    }

    .author-section [data-creator] {
        font-size: 0.625rem;
    }

    .author-section [data-date] {
        font-size: 0.5rem;
    }

    .author-section .argo-promote-button {
        font-size: 0.5rem;
    }

}