@media (max-width: 30rem) {
    .upload {
        margin: 10% 0;
        max-width: 90%;
        min-width: 90%;
    }

    .main-upload h2 {
        font-size: 1rem;
    }

    #debate-question { /* the question textarea [textarea] */
        border: 2px solid var(--dark-blue);
        font-size: 1rem;
        resize: none;
        background-color: white;
        overflow: auto;
        padding: 0.75rem;
        border-radius: 10px;
        transition: 200ms linear;
        outline: none;
        margin-bottom: 1rem;
        font-family: 'Cairo', sans-serif;
        flex: 1 0 1;
    }
}
