/* ======== Debate Card ======== */
.debate-card /* [li] */ {
    border-radius: 8px;
    cursor: pointer;
    margin: 1rem 0;
    font-weight: 600;
    display: flex;
    align-items: center;
}


/* This is inside the card border, it includes the title of the debate */
.inside-debate-card-border /* [section] */ {
    border: 2px solid var(--dark-blue);
    border-radius: 8px;
    padding: 1rem;
    cursor: pointer;
    font-weight: 600;
    max-width: 50%;
    transition: 300ms ease;
}

    .inside-debate-card-border:hover {
        background-color: hsl(240, 20%, 92.5%);
    }

.outside-debate-card-border /* [section] */ {
    display: flex;
    margin-left: auto;
}
/* these are the elements outside the debate card border */
.outside-debate-card-border span /* [span] */{
    margin: 0 0.5rem;
}

/* The thumbs up and down icons */
.outside-debate-card-border span svg /* [svg] */{
    margin-right: 0.25rem;
}


/* ======== Debate Card [end] ======== */

@media (max-width: 30rem) {
    .inside-debate-card-border /* [section] */ {
        padding: 0.5rem;
        cursor: pointer;
        font-size: 0.75rem;
    }

    .outside-debate-card-border /* [section] */ {
        font-size: 0.75rem;
    }

    .outside-debate-card-border span /* [span] */{
        margin: 0 0.25rem;
    }
    
    /* The thumbs up and down icons */
    .outside-debate-card-border span svg /* [svg] */{
        margin-right: 0.25rem;
    }

}