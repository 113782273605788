.navbar { /* the main navbar */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    position: fixed;
    top: 1rem;
    left: 0;
    right: 0;
    z-index: 3;
}
    
    .navbar-logo { /* the logo on th left hand side [img]*/
        max-height: 3rem;
        padding: 0.5rem;
        margin: 0 2rem;
        cursor: pointer;
    }

    /*====================== Profile section ==================== */

    /* the profile menu on the right hand side */
    /* encapsulates user-section and login-section, if the user is not logged in */
    
    .profile-section { 
        margin-left: auto;
    }

    .user-profile-section {
        display: flex;
        align-items: center;
        margin: 0 2rem;
        cursor: pointer;
        position: relative;
        border-radius: 10px;
    }

    .user-tutorial-button {
        margin: 0rem 1rem;
        font-size: 1rem;
        outline: none;
        border: none;
        cursor: pointer;
        border-radius: 1rem;
        color: #4848D6;
        transition: 300ms ease;
    }

    .user-profile-section img { /* user profile picture */
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        margin: 0.5rem;
    }

    .navbar-item svg { /* the chevron used to expand the menu */
        margin: 0 0.25rem; 
    }
        
        #userbox {
            white-space: nowrap;
            font-weight: 600;
        }

    .profile-dropdown {
        position: absolute;
        top: 140%;
        right: 1.5rem;
        border-radius: 10px;
        padding: 0.5rem;
        border: 2px solid var(--dark-blue);
        box-shadow: 0px 4px 4px -2px var(--dark-blue);
        list-style-type: none;
        padding-inline-start: 0;
        background-color: white;
        z-index: 2;
    }

        .profile-dropdown li { /* profile breakdown list item (ie logout button, user etc*/ 
            padding: 0.5rem; 
            margin: 0.5rem;
            min-width: 8rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-radius: 8px;
        }
            .profile-dropdown li:hover {
                background-color: var(--white-blue);
            }
            
            .profile-dropdown svg { /* the icons in the dropdown */
                margin-right: 0.25rem;
            }
        
        .profile-dropdown a { /* this is the link to the profile picture */
            color: inherit; 
            text-decoration: none;
        }

        .sign-in-button {
            font-size: 1rem;
            margin: 0 2rem;
            border: none;
            padding: 0.25rem 0.75rem;
            cursor: pointer;
            background-color: white;
            color: var(--light-blue);
            transition: 0.2s;
            border-radius: 5px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
        }

        .sign-in-button:hover {
            filter: grayscale(0%) brightness(1.05);
        }

        .logout-button {
            background-color: white;
            color: var(--dark-blue);
            border: none;
        }

        .menu-divider {
            width: 80%;
            color: var(--dark-blue);
        }

    

        
