@media (max-width: 30rem) {

    .navbar {
        position: fixed;
    }
    
    .navbar-logo {
        max-height: 2rem;
        padding: 0.25;
        margin: 0;
    }

    .user-profile-section {
        margin: 0.25rem;
    }

    .user-profile-section img {
        max-width: 2rem;
        max-height: 2rem;
        margin: 0;
    }  

    .user-profile-section {
        margin: 0.25rem;
    }

    .user-profile-section img {
        max-width: 2rem;
        max-height: 2rem;
        margin: 0;
    }

    .sign-in-button {
        font-size: 1rem;
        font-weight: 600;
        margin: 0 0.25rem;
        padding: 0.25rem;
    }

    .user-tutorial-button {
        margin: 0rem 1rem;
        padding: 0.5rem 1rem;
        font-size: 0.75rem;
        outline: none;
        border: none;
        cursor: pointer;
        border-radius: 1rem;
        color: white;
        background-color: #4848D6;
        transition: 500ms ease;
        position: relative;
    }

    .user-tutorial-button::before {
        all: unset;
    }

    .profile-dropdown {
        position: absolute;
        top: 140%;
        right: 1.5rem;
        border-radius: 10px;
        padding: 0.5rem;
        border: 2px solid var(--dark-blue);
        box-shadow: 0px 4px 4px -2px var(--dark-blue);
        list-style-type: none;
        padding-inline-start: 0;
        background-color: white;
        z-index: 2;
    }

}
