/* The entire argo contains the author section, rating, all the buttons, [li] */
.argo { 
    display: flex;
    align-items: center;
    position: relative;
    margin: 1.5rem 0.5rem;
    scroll-margin-top: 6rem;
    flex-wrap: wrap;
    padding: 0.25rem 0;
    border: 1px solid lightgray;
    border-radius: 0.25rem;
    text-align: justify;
    position: relative;
}

/* =========================== [Author Section (top left)] =========================== */

/* the author section (ie name, profile pic etc) */
.author-section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    padding: 0.25rem;
    border-radius: 10px;
    top: -1.25rem;
    left: -0.75rem;
    z-index: 2;
    width: 100%;
}

.author-section [data-creator] { /* this is the first small, with the creator and its pp */
    padding: 0 0.25rem;
    font-size: 0.75rem;
    background-color: var(--white2);
    border-radius: 0 10px 10px 0;
    cursor: pointer;
}

.author-section .profile-pic-argos { /* the picture */
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    cursor: pointer;
}
    
.author-section [data-date] { /* second small, this is the date the argument was made */
    padding: 0 0.25rem;
    font-size: 0.75rem;
    background-color: var(--white-blue);
    color: white;
    margin-left: 1rem;
    border-radius: 10px;
    cursor: pointer;
}

/* =========================== [Author Section (top left)][END] =========================== */

/* The upgrade button [button] */
.author-section .argo-promote-button {
    margin-left: auto;
    margin-right: 1rem;
    color: var(--white2);
    padding: 0.25rem 0.5rem; 
    border-radius: 1rem;
    background-image: linear-gradient(45deg, var(--blue-green), var(--light-green));
    display: flex;
    outline: none;
    border: none;
    cursor: pointer;
    gap: 0.25rem;
    font-weight: 400;
}

.author-section .argo-promote-button:hover {
    box-shadow: 
        1px 0px 2px 0px var(--blue-green),
        0px 1px 2px 0px var(--light-green);
}

/* =========================== [Bottom section (bottom)] =========================== */

.inside-argo-button { /* the reply, like, dislike buttons */
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    border-radius: 1rem;
    margin: 0.25rem;
    padding: 0rem 0.75rem 0rem 0.25rem;
    cursor: pointer;
    background-color: inherit;
    color: var(--dark-blue);
}

        /* the icons inside each button */
        .inside-argo-button svg { /* the icon before the text inside the button. [svg] */
            font-size: 1rem;
            padding: 0.5rem 0.25rem;
            margin: 0rem 0.25rem;
        }


#argo-content { /* [p] text inside a argo */
    flex: 1 1 100%;
    padding: 0rem 1rem 0.25rem 1rem;
    margin: 0 0 0.25rem 0;
    font-size: 0.875rem; /* 14px */
    hyphens: manual;
}

/* =========================== [Bottom section (bottom)][END] =========================== */


/*======================================== Owner options section ==========================================================*/

    .owner-options-display-button { /* the three-dotted button used to display the owner options */
        margin: 0.25rem;
        padding: 0.25rem;
        border-radius: 50%;
        cursor: pointer;
    }
        .owner-options-display-button:hover {
            background-color: var(--white-blue);
        }
        .owner-options-display-button:active {
            filter: brightness(150%);
        }
    
    .owner-button {
        margin: 0.25rem;
        padding: 0.25rem;
        border-radius: 50%;
        cursor: pointer;
    }
        .owner-button:hover {
            background-color: var(--white-blue);
        }
        .owner-button:active {
            filter: brightness(150%);
        }
    
/*======================================== Owner options section [end] ==========================================================*/