body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
   padding: 0;
   background-color: hsl(231, 20%, 95%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* =========== Utilities section =========== */

.disabled {
    display: none;
}

.hidden {
    visibility: hidden;
}

/* =========== Utilities section [end] =========== */

/* =========== Variables section =========== */



:root {
    /* ================= Colours ================= */
    --black: hsl(240, 25%, 15%);
    --white1: hsl(230, 20%, 98.5%); 
    --white2: hsl(20, 35%, 95%);
    
    --blue-grey: hsl(240, 20%, 95%);
    --blue-grey-2: hsl(240, 7%, 83%);
    --blue-grey-3: hsl(240, 1%, 56%);

    --comment-highlight-grey: hsl(240, 20%, 93%);
    --bg-grey: hsl(240, 20%, 92.5%);


    --dark-blue: hsl(230, 20%, 35%);
    --dark-blue-accent: hsl(230, 20%, 45%);
    --white-blue: hsl(220, 50%, 85%);
    --light-blue: hsl(200, 80%, 50%);
    --strong-blue: hsl(220, 40%, 45%);
    --red: hsl(0, 65%, 45%);
    --dark-red: hsl(0, 83%, 33%);
    /* used mainly for debate background on hover */
    --orange: hsl(25, 100%, 50%);
    --orange-light: hsl(25, 100%, 75%);
    --strong-blue: hsl(220, 70%, 45%);
    --light-red: hsl(0, 50%, 80%);
    --logo-blue: hsl(207, 56%, 55%);
    --blue-green: hsl(178, 98%, 25%);
    --light-green: hsl(121, 42%, 38%);
    /* background-color: #e63946; */

    /* ================= Widths ================= */

    --width1: 30rem;

}


::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    /* color: pink; */
}

/* =========== Variables section [end] =========== */

@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap');



