/*=================== Debate Media Section =========================*/

.debate-media-section /* [section] container for the image or youtube video */{
    margin-bottom: 1.75rem;
    padding: 0.5rem;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.debate-video /* [div] container for the iframe where the youtube video is embeded */ {
    display: flex;
    width: 100%;
}

.debate-video iframe /* [iframe] the iframe for youtube video embed */ {
    flex: 1;
}

.debate-image /* [div] container for the image upload*/{
    width: 100%;
}

@media (max-width: 30rem) {
    .debate-media-section {
        width: 100%;
        margin-bottom: 0rem;
    }
    
}

/*=================== Debate Media section [end] =========================*/