.rating-chip /* div containing the like and dislike buttons */ {
    display: flex;
    border: 1px solid lightgrey;
    border-radius: 1rem;
}

.rating-chip button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    padding: 0rem 0.75rem 0rem 0.25rem;
    cursor: pointer;
    background-color: inherit;
    color: var(--dark-blue);
}
.rating-chip button svg {
    font-size: 0.75rem;
    padding: 0.5rem 0.25rem;
    margin: 0rem 0.25rem;
}


/* TODO: hover effects, try integrating 3d graphics*/
#argo-reply-button {
    font-weight: 600;
    margin: 0 auto 0 1.25rem;
}

#argo-reply-button:hover {
    background-color: var(--white-blue);
}

/* the like buttn before the vote */
#argo-like-button-pre {
    border-radius: 1rem 0 0 1rem;
}
        
/* the like buttn after the vote (basically active) */
#argo-like-button-post {
    border-radius: 1rem 0 0 1rem;
    color: var(--strong-blue);
}

    /* The hover effect should be the same for both */
    #argo-like-button-pre:hover, #argo-like-button-post:hover {
        background-color: var(--white-blue);
        color: var(--strong-blue);         
    }

/* the like button before the vote */
#argo-dislike-button-pre {
    border-radius: 0 1rem 1rem 0;
}

#argo-dislike-button-post {
    border-radius: 0 1rem 1rem 0;
    color: var(--red);
}

#argo-dislike-button-pre:hover, #argo-dislike-button-post:hover {
    background-color: var(--light-red);
    color: var(--red);
}