/* loader animation */

#loader-animation {
    width: 7rem;
    height: 7rem;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

#loader-animation div {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    border: solid 0.5rem;
    border-color: var(--red) var(--red) var(--blue-grey) var(--blue-grey); 
    animation: spin 1500ms forwards infinite; 
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

