/*=============Add Reply Form=====================*/
.add-argo-form {
    display: flex;   
    align-items: center;
    justify-content: center;
    margin: 1rem;
    border-radius: 10px;
    border: 2px solid var(--dark-blue);
    background-color: white;
    min-width: min(22.5rem, 90%);
}
    
    .add-argo-form textarea { /* the input field, [textarea] */
        flex: 1;
        padding: 0.5rem;
        border-radius: 10px;
        resize: none;
        outline: none;
        border: none;
        font-size: 1rem;
        font-family: 'Cairo', sans-serif;
        background-color: white;
    }

    #add-argo-button { /* the button on the textarea */ 
        padding: 0.5rem;
        margin: 0.75rem;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: fit-content;
        transition: 200ms linear;
        border: none;
        outline: none;
        background: var(--red);
        font-weight: 800;
        color: white;
        border: 2px solid var(--dark-red);
        box-shadow: 0px 4px 0px 0px var(--dark-red);
    }
        /* The bullhorn icon (svg) */
        #add-argo-button svg {
            margin-right: 0.5rem;
            font-size: 18px;
        }   
        #add-argo-button:hover {
            /* background-color: var(--white-blue); */
        }



@media (max-width: 30rem) {
    .add-argo-form {
        margin: 0.5rem;
    }

    .add-argo-form textarea {
        font-size: 0.75rem;
    }

    #add-argo-button {
        padding: 0.25rem;
        margin: 0.5rem;
        border-radius: 5px;
        font-size: 0.75rem;
        font-weight: 800;
    }
}
    


/*=============Add Reply Form [END]=====================*/
