/* page headers */
#user-profile /* [main] just a wrapper for this page*/ {
    margin-top: 10%; /* this is to offset the navbar*/
    width: 70%;
}

.user-profile-header /* the [header] where the owner chip will be*/ {
    border-bottom: 1px solid var(--dark-blue);
    display: flex;
    align-items: center;
    gap: 1rem;
}


#user-profile-chip /*[span] owner chip basically */ {
    padding: 0.5rem 2rem;
    border-radius: 1rem;
    margin: 1rem 0;
    font-weight: 600;
    cursor: pointer;
    background-color: #bd79db;
    /* background-color: #e63946; */
    color: white;
}


#user-insight-chip /* [span] containing the amount of insight a user has */ {
    padding: 0.5rem 2rem;
    border-radius: 1rem;
    margin: 1rem 0;
    font-weight: 600;
    cursor: pointer;
    background: linear-gradient(45deg, #f8c88a, #e69833);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

#user-insight-chip svg /* the [svg] from react icons (the lightbulb) */{
    min-width: 1rem;
    min-height: 2rem;
    margin: 0 0.5rem;
}


/* =========== Sections  ===========*/

.user-section {
    border-bottom: 1px solid lightgrey;
    margin-top: 0.5rem;
}

/* =========== Sections [end]  ===========*/

/* ======== My debates - header ======== */

.debates-header h1,
.profile-header h1 {
    color: var(--dark-blue);
    margin: 0rem;
}

/* ======== My debates - header [end] ======== */

/* ============ Debate list ============ */

.debate-list {
    color: var(--dark-blue);
    padding: 0.5rem 0rem;
    list-style-type: none;
    padding-inline-start: 0; 
}

/* ============ Debate list  [end] ============ */


/* =============  Avatar forms and upload ============= */

.avatar-upload /* [form] the form containing the avatar upload */{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.avatar-upload-title /* [h4] form title that says choosing your own avatar */{
    width: 100%;
    color: var(--dark-blue);
    font-style: italic;
    margin: 0.75rem 0;
}

.default-avatar-list /* [ul] list that contains the default avatar cards */ {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;  
    flex-wrap: nowrap;  
    gap: 2.5rem;
    width: 100%;
    margin: 2rem 0;
    padding-inline-start: 0;
}

#submit-avatar-button {
    margin: 0.5rem 1rem;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 1rem;
    background-color: #bd79db;
    color: white;
    font-weight: 600;
    cursor: pointer;
}

/* see AvatarCard-syles.css for .default-avatar-card [li] */

.avatar-upload span {
    font-size: 1rem;
    padding: 1rem 0;
    font-weight: 600;
    color: var(--dark-blue);
    width: 100%;
}

.user-supplied-avatar {
    border-radius: 50%;
    margin: 0.5rem 0.25rem;
    height: 8rem;
    width: 8rem;
    cursor: pointer;
    /* width: 100%; */
}

/* =============  Avatar forms and upload ============= */

/* ============ Settings  ============ */

/* [span] that says please confirm your email. */
.email-confirmation-notice {
    display: flex;
    align-items: center;
    margin: 0.25rem;
    font-size: 1rem;
    padding: 1rem;
    color: var(--strong-blue);
    background-color: var(--white-blue);
}

.email-confirmation-notice svg {
    margin-right: 0.25rem;
}

.email-confirmation-btn {
    outline: none;
    background: none;
    color: var(--red);
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    margin-left: auto;
    border-radius: 0.25rem;
    border: 2px solid var(--red);
    transition: 200ms;
}

.email-confirmation-btn:hover {
    box-shadow: 0px 1px 2px -1px var(--dark-red);
}

.delete-user-btn {
    outline: none;
    background: none;
    color: var(--red);
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    margin-left: auto;
    margin-top: 1.5rem;
    border-radius: 0.25rem;
    border: 2px solid var(--red);
}
@media (max-width: 30rem) {
    .delete-user-notice {
        font-size: 0.75rem;
    }
}

.avatar-upload-separator {
    border-top: 1px solid lightgrey;
}
