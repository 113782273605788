footer {
    margin-top: auto;
}

footer ul {
    list-style: none;
    display: flex;
    justify-content: right;
    margin: 0px;
}

footer li {
    margin: 0 0.25rem;
    padding: 0.25rem;
    color: var(--dark-blue);
    cursor: pointer;
    font-size: 0.75rem;
}

footer li:hover {
    text-decoration: underline;
}

footer a {
    color: inherit;
    text-decoration: none;
}

@media (max-width: 30rem) {
    footer li {
        font-size: 0.5rem;
    }
}