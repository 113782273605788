@media (max-width: 30rem) {
    .upload-button {
        color: var(--dark-blue);
        font-weight: 900;
        font-size: 1.25rem;
        padding: 0.75rem 1.5rem;
        margin: 30% 1rem 1rem 1rem;
        border: none;
        outline: none;
        background-color: inherit;
        transition: 200ms linear;
        border-radius: 8px;
        cursor: pointer;
        align-items: center;
        border: 2px solid var(--dark-blue);
        box-shadow: 0px 2px 1px 0px var(--dark-blue); 
    }

    .main {
        width: 100%;
    }

}