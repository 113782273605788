/* ======== Upload window section ======== */

.upload {
    margin: 7rem 0 3rem 0;
    padding: 1rem 0.5rem;
    display: flex;
    flex-direction: column;
    width: 45rem;
}

.upload h2 /* "What is the topic of your debate" [h2] */ {
    color: var(--dark-blue);
    margin: 1rem 0;
}

/* ======== Upload window section [end] ======== */

/* ======== Upload form section ======== */

.upload-form {
    display: flex;
    align-items: center;
    border: 1px solid var(--dark-blue);
    border-radius: 0.25rem;
    /* box-sizing: border-box; */
}


/* inputs */
#debate-question { /* the question textarea */
    box-sizing: border-box;
    font-size: 1rem;
    flex: 1;
    overflow: hidden;
    resize: none;
    background-color: inherit;
    padding: 0.75rem;
    transition: 300ms linear;
    outline: none;
    border: none;
    font-family: "Inter", sans-serif;
}

#upload-logo {
    margin: 0.75rem;
    width: 3rem;
    height: 3rem;
}

#final-submit-button { /* button at the end of the upload that will upload the debate */
    all: unset;
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0.5rem 1rem 0.5rem 0;
}

.rendered-facts-section /* [ul] the list of redered argos */ {
    list-style-type: none;
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}

.rendered-facts-section section /* [section] the items containing the <Argo> component and the bin button*/{
    display: flex;
    align-items: center;
}

.rendered-facts-section svg /* [button] the button*/ {
    font-size: 1.5rem;
    cursor: pointer;
}

/* ================== UPLOAD SUBSECTIONS GENERAL ================== */
.upload-subsection h2 /* [h2] Title: "Upload an image" */ {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
    gap: 0.5rem;
    padding: 0.75rem;
    border-radius: 1rem;
    cursor: pointer;
    transition: 300ms ease;
}

.upload-subsection h2:hover {
    background-color: rgb(211, 210, 210);
}

.upload-subsection h2 svg { 
    width: 2rem;
    height: 2rem;
}

/* ================== UPLOAD SUBSECTIONS GENERAL [END] ================== */

/* ================== IMAGE UPLOAD ================== */

.upload-debate-images-list /* [ul] the list of images*/ {
    list-style: none;
    display: flex;
    gap: 1rem;
    max-height: 25rem;
}

.upload-debate-image-item /* [li] container for image and remove image button*/ {
    position: relative;
}

.upload-debate-image-item img /* [li]>[img] each image rendered*/ {
    max-width: 90%;
    height: 90%;
}

.upload-debate-image-item svg /* [li]>[svg] the remove image button */ {
    position: absolute;
    top: -0.25rem; 
    left: -0.25rem;
    padding: 0.25rem;
    border-radius: 50%;
    background-color: #F0F0F5;
    font-size: 1.25rem;
    cursor: pointer;
}
    
/* ================== DEBATE IMAGE UPLOAD [end] ================== */

/* ================== DEBATE SOURCE UPLOAD ================== */
.add-source-section /* [div] the upload source section */ {
    
}
/* ================== DEBATE SOURCE UPLOAD [END] ================== */

/* ================== YOUTUBE UPLOAD ================== */

.display-youtube-section /* [section] inside youtube-section */{
    margin-left: 1rem;
    display: flex;
    gap: 0.75rem;
    align-items: center;
    justify-content: flex-start;
}


.display-youtube-section label svg/* [label] > [svg] for #youtube-link-input below */ {
    cursor: pointer;
    width: 1.5rem; 
    height: 1.5rem;
    border-radius: 50%;
    padding: 0.25rem;
    background-color: hsl(0, 0%, 71%);
    color: #F0F0F5;
}

#youtube-link-input {
    font-size: 1rem;
    padding: 0.75rem;
    border-radius: 1rem;
    margin: 0.5rem;
    outline: none;
    border: 2px solid var(--dark-blue);
    flex: 1 0 auto;
}

/* ================== YOUTUBE UPLOAD [END] ================== */

#add-debate-input {
    font-size: 1.5rem;
    resize: none;
    flex: 1;
}


