.default-avatar-card /* [li] default avatar card */{
    border: 2px solid var(--dark-blue);
    border-radius: 2rem;
    cursor: pointer;
    width: 15rem;
    height: 22rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #ef683e;
    filter: saturate(100%);
    transition: 300ms ease; 
}

.avatar-card-img /* [img] images from S3 */{
    width: 8rem;
    height: 8rem;
    padding: 0.75rem;
    background-color: #f2e9e4;
    border-radius: 50%;
    position: relative;
    top: -2rem;
    z-index: 3;
}


.default-avatar-card-top /* [div] an overlay for the top of the avatar */ {
    position: absolute;
    border: 2px solid var(--dark-blue);
    border-bottom: none; /* this removes some weird clipping */
    width: 100%;
    top: -0.25rem; /* eliminates this weird overlap*/
    margin: 0;
    height: 55%;
    border-radius: 2rem 2rem 0 0;
    z-index: 2;
    background-color: #f2e9e4;
    clip-path: polygon(0 0, 100% 0%, 100% 65%, 0% 100%);
}

