.comment-section {
    display: flex;
    flex-direction: column;
    border-top: 1px solid lightgrey;
    min-width: min(22.5rem, 90%);
    width: 100%;
}

    .add-comment-form {
        display: flex;
        padding: 0.5rem 0;
        align-items: center;
    }

    .add-comment-form img {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        margin: 0 0.5rem;
    }

    .add-comment-form textarea {
        flex: 1;
        resize: none;
        font-family: 'Cairo', sans-serif;
        padding: 0.5rem;
        font-size: 1rem;
        font-weight: 400;
        border-radius: 2px;
        border-color: var(--dark-blue);
    }

    .add-comment-button {
        text-decoration: none;
        border: none;
        cursor: pointer;
        background-color: white;
        padding: 0;
        margin: 0 0.25rem;
    }

    .debate-statistics {
        padding: 0.25rem;
        font-size: 0.75rem;
        border: 1px solid var(--dark-blue);
    }

    .comment-list {
        list-style-type: none;
        padding-left: 0;
        padding-bottom: 1rem;
        margin: 1rem 0;
        min-width: min(22.5rem, 90%);
        margin-block-start: 0;
        margin-block-end: 0;
        width: 100%;
    }


.reply-section {
    padding-inline-start: 0;
    margin-left: 2.5rem;
}

@media (max-width: 30rem) {
    .reply-section {
        margin-left: 1.5rem;
    }
}