/* ====================== ArgoHeader ====================== */

.argo header /* wrapper for tags and insight sections */{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem;
    width: 100%;
    gap: 1rem;
    /* the min-width thing I don't really understand, but it's necessary otherwise the overflow causes the header to run off page */
    min-width: 0; 
}

/* ====================== ArgoHeader [end] ====================== */

/* ====================== Tags section ====================== */

.argo-tags-section /* [section] where we store the tags of the argument */ {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    min-width: 0;
}

.inreplyto-tag {
    border-radius: 1rem;
    padding: 0 0.25rem;
    font-size: 0.75rem;
    cursor: pointer;
    background-color: #AED4FF;
    color: #474D83;
    /* the following stuff clips the text of the reply */
    max-width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
}

.inreplyto-creator {
    margin-right: 0.5rem;
    font-weight: 600;
}

.inreplyto-content {
    font-size: 0.75rem;
}

/* ====================== Tags section [end] ====================== */

/* ====================== Insight-section ====================== */

.insight-section {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.insight-section svg { /* this insight lightbulb */
    border-radius: 50%;
    font-size: 1rem;
    padding: 0 0.25rem;
}

.insight-section p {
    margin: 0;
    font-size: 0.75rem;
}

.insight-section .insight-number {
    font-size: 0.75rem;
    padding: 0 0.25rem;
    margin: 0 0.25rem;
}

/* ====================== Insight-section [end] ====================== */