/* =========== Feedback section =========== */

.feedback-section {
    display: flex;
    justify-content: space-evenly;
    /* position: absolute;
    top: 80%; */
}

/* ================= The vote button ================= */
    #interest-button {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        outline: none;
        border: none;
        font-size: 1.5rem;
        font-weight: 900;
        color: var(--bg-grey);
        margin: 0.75rem auto;
        background-color: var(--strong-blue);
        padding: 0.5rem 1rem;
        border-radius: 3rem;
    }

        /* the svg symbol within the vote button*/ 
        #interest-button svg {
            margin-left: 0.5rem;
            margin-top: 0.25rem;
        }
    
/* ================= The vote button [end] ================= */

/* ================= The initial feedback (like/dislike) ================= */

    .initial-feedback-section {
        display: flex; 
        justify-content: space-between;
        flex-direction: row;
    }

    .for-against-section button {
        border: none;
        cursor: pointer;
        background-color: inherit;
        color: var(--dark-blue);
        font-size: 1.75rem;
        padding: 0.5rem;
        margin: 0.5rem 1rem;
        /* border-radius: 50%; */
        opacity: 0.5;
        transition: opacity 200ms linear;
    }
    .for-against-section button:hover {
        opacity: 1;
        box-shadow: 0 0.5rem 0.5rem -0.5rem var(--red);
    }


/* =========== Initial feedback section [end] =========== */

/* =========== Rejoin debate button =========== */

#rejoin-debate-button {
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 900;
    color: var(--bg-grey);
    border: none;
    background-color: var(--red);
    outline: none;
    margin: 0.75rem auto;
    padding: 0.5rem 1rem;
    border-radius: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

    #rejoin-debate-button span {
        flex-grow: 1;
    }

    /* the svg symbol within the vote button*/ 
    #rejoin-debate-button svg {
        flex-grow: 1;
        margin-right: 0.5rem;
    }

    
@media (max-width: 30rem) {
    #rejoin-debate-button {
        font-size: 1.125rem;
    }
}


/* =========== Rejoin debate button [end] =========== */