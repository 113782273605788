@media (max-width: 30rem) {
    .debate {
        min-width: 0%;
        margin: 0.75rem 0;
        padding: 0.25rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        transition: 200ms linear;
        border: none;
        border-radius: 0;
        border-top: 1px solid var(--dark-blue);
        position: relative;
        width: 95%; /* relative to .main which is 100% of the viewport */
    }

    .debate-question { /*[h1] contains the debate question */
        font-size: 1.25rem;
        margin: 0.5rem 0;
        color: var(--dark-blue);
        hyphens: manual;
    }

    .owner-chip /* [section] the top left section that includes the avatar, the username and the date*/{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: baseline;
    }

    #debate-avatar /* [img] the avatar of the user */ {
        width: 2rem; /* [HARDCODED] */
        height: 2rem; /* [HARDCODED] */
        margin: 5% 0.25rem 5% 0;
    }

    #debate-creator /*[span] within the userchip, a span that contains the userName */ {
        /* padding: 0.25rem 0.5rem; */
        background-color: var(--white2);
        padding: 0.25rem;
        font-size: 0.625rem;
        font-weight: 600;
        border-radius: 10px;
    }

    #debate-creation-date {
        font-size: 0.625rem;
    }
    
    #viewership {
        font-size: 0.865rem;
        font-weight: 600;
    }

    .options-panel /*[ul]*/{
        padding: 0;
        margin: 0;
        max-width: 1rem;
        max-height: 1rem;
        position: unset; /* set to relative in the web version*/
    }

    .options-panel-display-button {
        margin: 0;
        padding: 0;
        min-width: 0;
        min-height: 0;
        max-width: 1rem;
        max-height: 1rem;
        border-radius: 50%;
        cursor: pointer;
    }

    .options-panel-list /* [ul] the list of options for a debate */{
        position: absolute;
        display: flex;
        flex-direction: column;
        list-style: none;
        background-color: var(--blue-grey);
        z-index: 10;
        right: 0.75rem;
        left: unset;
        top: unset;
        top: 1.5rem;
        border-radius: 0.25rem;
        border: 2px solid var(--dark-blue);
        box-shadow: none;
        min-width: 25%;
        width: 25%;
    }

    /* The option items [li] in the threedotted pannel at the top rght corner of a debate  */
    .options-panel-item { 
        padding: 0.5rem;
        margin: 0.25rem 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        border-radius: 0;
    }

        /* The svg inside the option item (li) */
    .options-panel-item svg {
        margin-right: 0.5rem;
        font-size: 1rem;
        min-width: 1rem;
        min-height: 1rem;
    }

    .options-panel-item:hover {
        background-color: var(--white-blue);
        font-weight: 600;
    }



}
