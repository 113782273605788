@media (max-width: 30rem) {
    #user-profile /* [main] just a wrapper for this page*/ {
        margin-top: 15%;
        width: 95%;
    }

    .user-profile-header /* the [header] where the owner chip will be*/ {
        border-bottom: 1px solid var(--dark-blue);
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .default-avatar-list /* [ul] list that contains the default avatar cards */ {
        list-style: none;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start; 
        flex-wrap: nowrap;  
        gap: 1rem;
        margin: 1.5rem 0;
        padding: 0.25rem 0;
        padding-inline-start: 0;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
    }

    .default-avatar-card /* [li] default avatar card */{
        flex: 1 0 66%;
    }


}