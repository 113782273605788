/* =========== Main debate section =========== */

.upload-button {
    color: var(--dark-blue);
    font-weight: 900;
    font-size: 1.5rem;
    padding: 1rem 1.5rem;
    margin: 33% 1rem 0.5rem 1rem;
    border: none;
    outline: none;
    background-color: inherit;
    transition: 200ms linear;
    border-radius: 8px;
    cursor: pointer;
    align-items: center;
    border: 1px solid var(--dark-blue);
}

.upload-button a {
    text-decoration: none;
}
    .upload-button:hover {

    } 
    .upload-button:active {
        box-shadow: none;
    } 

.tiny-upload-button {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 1rem; 
    right: 1rem;
    padding: 1rem;
    border-radius: 50%; 
    outline: none;
    border: none;
    color: white;
    background-image: linear-gradient(45deg, var(--strong-blue), var(--logo-blue));
    cursor: pointer;
}

.bigger-upload-button {
    position: fixed;
    display: flex;
    color: var(--dark-blue);
    font-weight: 900;
    font-size: 1.5rem;
    bottom: 1rem; 
    right: 1rem;
    padding: 1rem 1.5rem;
    border: none;
    outline: none;
    background-color: inherit;
    transition: 200ms linear;
    border-radius: 8px;
    cursor: pointer;
    align-items: center;
    border: 2px solid var(--dark-blue);
    box-shadow: 0px 4px 2px 0px var(--dark-blue); 
}


/* =========== Main debate section [end] =========== */
